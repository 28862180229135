import React from 'react'
import { graphql, Link} from 'gatsby'
import Img from 'gatsby-image'
import Layout from "../components/layout"
import SEO from "../components/seo"



const Prodotti = ({ data }) => {
    const prodotto = data.prodotto;
    const immagini = data.prodotto.immagini || [];
    const immagineHero = immagini && immagini[0];
    return (
        <Layout>
            <SEO title={prodotto.titolo} immagineHero={immagineHero}/>
            <div className="row">
                <div className="col-12 text-center">
                    <h1 className="pagetitle">{prodotto.titolo}</h1>
                </div>
            </div>
            {!!prodotto.testo &&
                <div className="section section--top">
                    <div className="row">
                        <div className="col-12">
                            <div className="section__text"  dangerouslySetInnerHTML={{__html: prodotto.testo.childMarkdownRemark.html}} />
                        </div>
                    </div>
                </div>
            }
            <div className="row">
                {immagini.map((item,index) => {
                    return (
                        <div key={index} className="col-sm-6">
                            <Img className="card" fluid={item.fluid} />
                        </div>
                    )
                })}
            </div>
            <div className="section">
                <div className="row">
                    <div className="col-12 text-center">
                        <Link to="/cosa-faccio/ricette-disegnate#esempi" className="btn-action">Torna indietro</Link>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
    query ProdottiQuery($contentful_id: String!) {
        prodotto: contentfulProdotti(contentful_id: {eq: $contentful_id}){
            titolo
            testo {
                childMarkdownRemark {
                    html
                }
            }
            immagini {
                fluid(maxWidth: 600) {
                    ...GatsbyContentfulFluid_withWebp
                }
            }
        }  
    }
`;

export default Prodotti;
